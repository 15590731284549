import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import {Navigate, useNavigate } from 'react-router-dom';

import { login } from "../../slices/auth";
import { clearMessage } from "../../slices/message";
import login_banner from '../../assets/images/login-banner.png';
import logo from '../../assets/images/logo.png';

const Login = ({setLoader}) => {

    const navigate = useNavigate();

    const { isLoggedIn } = useSelector((state) => state.auth);
    const { message } = useSelector((state) => state.message);

    const dispatch = useDispatch();

    // useEffect(() => {
    //   dispatch(clearMessage());
    // }, [dispatch]);

    const [userCredentials, setUserCredentials] = useState({
        email: "",
        password: ""
      });
    
      const [showpassword, setShowpassword] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader(true)
        dispatch(login(userCredentials))
        .then((response) => {
          console.log(response.data);
          navigate("/", { replace: true })
          setLoader(false);
        })
        .catch(()=>{
          setLoader(false);
        })
      };
    
      const handleClickShowPassword = () => {
        setShowpassword(!showpassword);
      };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

      if (isLoggedIn) {
        return <Navigate to="/" replace/>;
      }

  return (
    <Grid container sx={{height:'100vh'}}>
   <Grid item xs={6} sx={{bgcolor: `primary.light`, display:'flex', justifyContent:'center', alignItems:'center'}}>
    <img src={login_banner} alt='login_banner' width='100%' style={{objectFit:'cover', height:'100%'}}></img>
  </Grid>
  <Grid item xs={6}     sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
  <Box
    component="form"
    noValidate
    autoComplete="off"
    onSubmit={handleSubmit}>
        <Paper sx={{width: 500, p: 3, boxShadow:'none'}}>
            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', mb: 1}}>
            <img src={logo} alt='logo' width={100}></img>
            </Box>
            <Typography variant='h5' sx={{textAlign: 'center'}}>Super Admin Login</Typography>
            <Grid container spacing={2} sx={{mt: 1}}>
            <Grid item xs={12}>
                  <Typography variant='body1' sx={{mb: 1}}>Email ID</Typography>   
                    <TextField fullWidth id="outlined-basic" placeholder='Enter Your Email ID' variant="outlined" 
                    value={userCredentials.email}
                    onChange={(e) =>
                      setUserCredentials({
                        ...userCredentials,
                        email: e.target.value,
                      })
                    }
                    />
                </Grid>
                <Grid item xs={12}>
                <Typography variant='body1' sx={{mb: 1}}>Password</Typography>   
                <FormControl sx={{ width: "100%" }} variant="outlined">
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showpassword ? "text" : "password"}
                      value={userCredentials.password}
                      onChange={(e) =>
                        setUserCredentials({
                          ...userCredentials,
                          password: e.target.value,
                        })
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showpassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      placeholder='Enter Your Password'
                    />
                  </FormControl>                </Grid>
                  <Grid item xs={12}>
                  <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                  <FormGroup>
  <FormControlLabel control={<Checkbox />} label="Remember me" />
  </FormGroup>     
  <Button variant='text'>Forgot Password?</Button> 
                  </Box>        
                   </Grid>
                <Grid item xs={12}>
                    <Button fullWidth type='submit' variant='contained' sx={{py: 1.5, marginTop: 2}}>Login</Button>
                </Grid>
            </Grid>
            {message && (
          <Box sx={{textAlign:'center', mt: 2}}>
              <Typography variant='p'>{message}
              </Typography>
          </Box>
      )}
        </Paper>
    </Box>
  </Grid>
</Grid>
  )
}

export default Login